<template>
  <div id="app">
    <section class="banner banner-one">
      <div data-parallax="{&quot;y&quot; : 230}" class="circle-shape"
           style="transform: translate3d(0px, 14.126px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1);">
        <img src="media/banner/circle-shape.png" alt="circle"></div>
      <div class="container">
        <div class="banner-content-wrap">
          <div class="row align-items-center">
            <div class="col-lg-5">
              <div class="banner-content"><h1 data-wow-delay="0.3s" class="banner-title wow pixFadeUp"
                                              style="visibility: visible; animation-delay: 0.3s; animation-name: i; font-weight: 100 !important; letter-spacing: 1px;">
                Uma <span>Plataforma<br>Revolucionária!</span></h1>
                <p data-wow-delay="0.5s" class="description wow pixFadeUp"
                   style="visibility: visible; animation-delay: 0.5s; animation-name: i; font-weight: 100 !important; letter-spacing: 1px; font-size: 16px;">
                  JasSy é uma plataforma de gestão comercial completa para o gerenciamento do seu bar, delivery ou restaurante. </p><a href="#planos" data-wow-delay="0.6s" class="pxs-btn banner-btn wow pixFadeUp"
                                  style="visibility: visible; animation-delay: 0.6s; animation-name: i; background: white; color: rgb(0, 123, 255);">Ver
                  Planos</a></div>
            </div>
            <div class="col-lg-1"></div>
            <div class="col-lg-6">
              <div class="promo-mockup wow pixFadeLeft" style="visibility: visible; animation-name: k;"><a target="_blank" href="https://www.youtube.com/watch?v=JVZt6gBnf9I&t="><img
                  src="img/jassy.png" alt="mpckup"></a></div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-shape"><img src="img/shape-bg.png" alt=""></div>
    </section>
    <section class="featured">
      <div class="container">
        <div class="section-title text-center wow pixFade" style="visibility: visible; animation-name: h;"><h3
            class="sub-title">MAIS AGILIDADE NO SEU ATENDIMENTO</h3>
          <h2 class="title">Tudo o que você precisa em um único aplicativo</h2></div>
        <div class="row">
          <div class="col-md-4">
            <div data-wow-delay="0.3s" class="saaspik-icon-box-wrapper style-one wow pixFadeLeft"
                 style="visibility: visible; animation-delay: 0.3s; animation-name: k;">
              <div class="saaspik-icon-box-icon"><img src="img/1.png" alt=""></div>
              <div class="pixsass-icon-box-content"><h3 class="pixsass-icon-box-title"><a href="#">Acompanhe a
                movimentação do seu estabelecimento de onde estiver através da internet.</a></h3></div>
            </div>
          </div>
          <div class="col-md-4">
            <div data-wow-delay="0.5s" class="saaspik-icon-box-wrapper style-one wow pixFadeLeft"
                 style="visibility: visible; animation-delay: 0.5s; animation-name: k;">
              <div class="saaspik-icon-box-icon"><img src="img/2.png" alt=""></div>
              <div class="pixsass-icon-box-content"><h3 class="pixsass-icon-box-title"><a href="#">Aplicativo para
                smartphone para retirada de pedidos, Wi-Fi Check-In integrado ao sistema.</a></h3></div>
            </div>
          </div>
          <div class="col-md-4">
            <div data-wow-delay="0.7s" class="saaspik-icon-box-wrapper style-one wow pixFadeLeft"
                 style="visibility: visible; animation-delay: 0.7s; animation-name: k;">
              <div class="saaspik-icon-box-icon"><img src="img/3.png" alt=""></div>
              <div class="pixsass-icon-box-content"><h3 class="pixsass-icon-box-title"><a href="#">Todas as ferramentas
                inclusas em qualquer plano, sem custo adicional.</a></h3></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="editor-design">
      <div class="container">
        <div class="row">
          <div class="editure-feature-image wow pixFadeRight" style="visibility: visible; animation-name: l;">
            <div class="image-one" style="transform: translateX(-6.475px) translateY(-8.23529px);"><img src="img/4.png"
                                                                                                        data-wow-delay="0.3s"
                                                                                                        alt="feature-image"
                                                                                                        class="wow pixFadeRight"
                                                                                                        style="visibility: visible; animation-delay: 0.3s; animation-name: l;">
            </div>
            <div class="image-two">
              <div class="image-two-inner" style="transform: translateX(-16.1875px) translateY(-20.5882px);"><img
                  src="img/41.png" data-wow-delay="0.5s" alt="feature-image" class="wow pixFadeLeft"
                  style="visibility: visible; animation-delay: 0.5s; animation-name: k;"></div>
            </div>
          </div>
          <div class="col-lg-6 offset-lg-6">
            <div class="editor-content">
              <div class="section-title style-two"><h2 data-wow-delay="0.3s" class="title wow pixFadeUp"
                                                       style="visibility: visible; animation-delay: 0.3s; animation-name: i;">
                Gerenciador de comandas completo </h2>
                <p data-wow-delay="0.5s" class="wow pixFadeUp"
                   style="visibility: visible; animation-delay: 0.5s; animation-name: i;"> JasSy possui um dos sistemas
                  mais completos de comanda eletrônica </p></div>
              <div data-wow-delay="0.7s" class="description wow pixFadeUp"
                   style="visibility: visible; animation-delay: 0.7s; animation-name: i;"><p>Identificação da comanda
                por cliente, mesa, balcão, tele entrega ou cartão comanda. Divisão dos itens por cadeira na comanda.</p>
                <a href="#contato" data-wow-delay="0.9s" class="pix-btn wow pixFadeUp"
                   style="visibility: visible; animation-delay: 0.9s; animation-name: i;"> Quero saber mais </a></div>
            </div>
          </div>
        </div>
      </div>
      <div class="shape-bg"><img src="img/shape_bg.png" alt="shape-bg" class="wow fadeInLeft"
                                 style="visibility: visible; animation-name: fadeInLeft;"></div>
    </section>
    <section data-v-dafa6156="" class="genera-informes">
      <div data-v-dafa6156="" class="container">
        <div data-v-dafa6156="" class="row">
          <div data-v-dafa6156="" class="col-lg-6 pix-order-one">
            <div data-v-dafa6156="" class="section-title style-two"><h2 data-v-dafa6156="" class="title wow pixFadeUp"
                                                                        style="visibility: visible; animation-name: i;">
              Sistema de Pagamentos Exclusivo </h2>
              <p data-v-dafa6156="" data-wow-delay="0.3s" class="wow pixFadeUp"
                 style="visibility: visible; animation-delay: 0.3s; animation-name: i;"> JasSy possui um sistema de
                pagamentos completo, que inclui integração com seus meios de pagamento, além de uma poderosa divisão de
                comanda. </p></div>
            <ul data-v-dafa6156="" data-wow-delay="0.4s" class="list-items wow pixFadeUp"
                style="visibility: visible; animation-delay: 0.4s; animation-name: i;">
              <li data-v-dafa6156=""><i data-v-dafa6156="" class="fas fa-check-circle" aria-hidden="true"></i>
                Integração com o PIX*
              </li>
              <li data-v-dafa6156=""><i data-v-dafa6156="" class="fas fa-check-circle" aria-hidden="true"></i>
              Integração as maquininhas do PagSeguro
              </li>
              <li data-v-dafa6156=""><i data-v-dafa6156="" class="fas fa-check-circle" aria-hidden="true"></i>
                Integração ao PicPay
              </li>
              <li data-v-dafa6156=""><i data-v-dafa6156="" class="fas fa-check-circle" aria-hidden="true"></i> Divisão
                da Comanda por Igual
              </li>
              <li data-v-dafa6156=""><i data-v-dafa6156="" class="fas fa-check-circle" aria-hidden="true"></i> Divisão
                da Comanda por Itens
              </li>
            </ul>
            <a data-v-dafa6156="" data-recording-click-props='{"whatsapp":true}' href="https://wa.me/555140427702" data-wow-delay="0.5s" class="pix-btn btn-outline wow pixFadeUp"
               style="visibility: visible; animation-delay: 0.5s; animation-name: i;">Quero saber mais</a></div>
          <div data-v-dafa6156="" class="informes-feature-image">
            <div data-v-dafa6156="" class="image-one" style="transform: translateX(-6.475px) translateY(-8.23529px);">
              <img data-v-dafa6156="" src="img/5.png" alt="informes" class="wow pixFadeDown"
                   style="visibility: visible; animation-name: j;"></div>
            <div data-v-dafa6156="" class="image-two" style="transform: translateX(-16.1875px) translateY(-20.5882px);">
              <img data-v-dafa6156="" src="img/51.png" data-wow-delay="0.3s" alt="informes"
                   class="mw-none wow pixFadeDown"
                   style="visibility: visible; animation-delay: 0.3s; animation-name: j;"></div>
          </div>
        </div>
      </div>
      <div data-v-dafa6156="" class="shape-bg"><img data-v-dafa6156="" src="img/shape.png" alt="shape-bg"
                                                    class="wow fadeInRight"
                                                    style="visibility: visible; animation-name: fadeInRight;"></div>
    </section>
    <section class="revolutionize">
      <div class="bg-angle"></div>
      <div class="container">
        <div class="section-title dark-title text-center"><h3 class="sub-title wow pixFadeUp"
                                                              style="visibility: visible; animation-name: i;">PRÁTICO E
          INTUITIVO</h3>
          <h2 data-wow-delay="0.3s" class="title wow pixFadeUp"
              style="visibility: visible; animation-delay: 0.3s; animation-name: i;">Tudo está ao seu alcance</h2></div>
        <div id="pix-tabs" data-wow-delay="0.5s" class="wow pixFadeUp"
             style="visibility: visible; animation-delay: 0.5s; animation-name: i;">
          <ul id="pix-tabs-nav">
            <li id="linktb-1" @click="tab=1" :class="{active: (tab===1)}"><a>Fácil Acesso</a></li>
            <li id="linktb-2" @click="tab=2" :class="{active: (tab===2)}"><a>Gerenciamento de Mesas</a></li>
            <li id="linktb-3" @click="tab=3" :class="{active: (tab===3)}"><a>Configure o Pedido</a></li>
            <li id="linktb-4" @click="tab=4" :class="{active: (tab===4)}"><a>Controle de Acesso</a></li>
          </ul>
          <div id="pix-tabs-content">
            <div id="tab1" class="content" :style="{display: (tab===1)?'block':'none'}"><img src="img/rev1.jpg" alt="revolutionize">
              <div class="shape-shadow"></div>
            </div>
            <div id="tab2" class="content" :style="{display: (tab===2)?'block':'none'}"><img src="img/rev2.jpg" alt="revolutionize">
              <div class="shape-shadow"></div>
            </div>
            <div id="tab3" class="content" :style="{display: (tab===3)?'block':'none'}"><img src="img/rev3.jpg" alt="revolutionize">
              <div class="shape-shadow"></div>
            </div>
            <div id="tab4" class="content" :style="{display: (tab===4)?'block':'none'}"><img src="img/rev4.jpg" alt="revolutionize">
              <div class="shape-shadow"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="featured-two">
      <div class="container">
        <div class="section-title text-center"><h3 class="sub-title wow pixFadeUp"
                                                   style="visibility: visible; animation-name: i;">PLATAFORMA
          COMPLETA</h3>
          <h2 data-wow-delay="0.2s" class="title wow pixFadeUp"
              style="visibility: visible; animation-delay: 0.2s; animation-name: i;">Você usa o que quiser, sem custo
            adicional!</h2></div>
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div data-wow-delay="0.4s" class="saaspik-icon-box-wrapper style-two wow pixFadeRight"
                 style="visibility: visible; animation-delay: 0.4s; animation-name: l;">
              <div class="saaspik-icon-box-icon"><img src="img/6.png" alt="feature"></div>
              <div class="pixsass-icon-box-content"><h3 class="pixsass-icon-box-title"><a href="#">Integração Total</a>
              </h3>
                <p>Receba os pedidos do iFood, uberEats, WhatsApp e até do seu site direto em um único lugar.</p></div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div data-wow-delay="0.5s" class="saaspik-icon-box-wrapper style-two wow pixFadeRight"
                 style="visibility: visible; animation-delay: 0.5s; animation-name: l;">
              <div class="saaspik-icon-box-icon"><img src="img/7.png" alt="feature"></div>
              <div class="pixsass-icon-box-content"><h3 class="pixsass-icon-box-title"><a href="#">Agilidade no
                Atendimento</a></h3>
                <p>Atenda seus clientes até 10x mais rápido. JasSy identifica seu cliente através do número de
                  telefone.</p></div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div data-wow-delay="0.6s" class="saaspik-icon-box-wrapper style-two wow pixFadeRight"
                 style="visibility: visible; animation-delay: 0.6s; animation-name: l;">
              <div class="saaspik-icon-box-icon"><img src="img/8.png" alt="feature"></div>
              <div class="pixsass-icon-box-content"><h3 class="pixsass-icon-box-title"><a href="#">
                Configure sua Cozinha
              </a></h3>
                <p>Crie mais de uma cozinha, determine para onde cada item deve ir. Use impressora ou monitores.</p></div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div data-wow-delay="0.7s" class="saaspik-icon-box-wrapper style-two wow pixFadeRight"
                 style="visibility: visible; animation-delay: 0.7s; animation-name: l;">
              <div class="saaspik-icon-box-icon"><img src="img/9.png" alt="feature"></div>
              <div class="pixsass-icon-box-content"><h3 class="pixsass-icon-box-title"><a href="#">Seus dados na
                Nuvem</a></h3>
                <p>JasSy possui todo seu processamento hospedado em alta disponibilidade na nuvem, garantindo maior
                  segurança para os seus dados.</p></div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div data-wow-delay="0.8s" class="saaspik-icon-box-wrapper style-two wow pixFadeRight"
                 style="visibility: visible; animation-delay: 0.8s; animation-name: l;">
              <div class="saaspik-icon-box-icon"><img src="img/10.png" alt="feature"></div>
              <div class="pixsass-icon-box-content"><h3 class="pixsass-icon-box-title"><a href="#">Menu Virtual</a></h3>
                <p>JasSy disponibiliza um menu virtual, que permite ao seu cliente realizar o próprio pedido através de
                  um QRCode instalado na mesa.</p></div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div data-wow-delay="0.9s" class="saaspik-icon-box-wrapper style-two wow pixFadeRight"
                 style="visibility: visible; animation-delay: 0.9s; animation-name: l;">
              <div class="saaspik-icon-box-icon"><img src="img/11.png" alt="feature"></div>
              <div class="pixsass-icon-box-content"><h3 class="pixsass-icon-box-title"><a href="#">Suporte Vitalício</a>
              </h3>
                <p>Equipe de suporte disponível 24/7 para resolver qualquer imprevisto.</p></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section data-v-7cd1ca14="" class="pricing"><a data-v-7cd1ca14="" name="planos"></a>
      <div data-v-7cd1ca14="" class="container-fluid">
        <div data-v-7cd1ca14="" class="section-title text-center"><h3 data-v-7cd1ca14="" class="sub-title wow pixFadeUp"
                                                                      style="visibility: visible; animation-name: i;">
          PLANOS DISPONÍVEIS</h3>
          <h2 data-v-7cd1ca14="" data-wow-delay="0.3s" class="title wow pixFadeUp"
              style="visibility: visible; animation-delay: 0.3s; animation-name: i;">Sem taxas escondidas<br
              data-v-7cd1ca14="">Tudo incluso em todos os planos</h2></div>
        <nav v-if="false" data-v-7cd1ca14="" class="pricing-tab wow pixFadeUp" :class="{seleceted: (anual===1)}"
             style="visibility: visible; animation-delay: 0.4s; animation-name: i;"><span data-v-7cd1ca14=""
                                                                                          class="tab-btn monthly_tab_title active" @click="anual=0">Mensal</span><span
            data-v-7cd1ca14="" class="pricing-tab-switcher" :class="{active: (anual===1)}" @click="anual=(anual===1)?0:1" ></span><span data-v-7cd1ca14=""
                                                                                class="tab-btn annual_tab_title active" @click="anual=1">Anual</span>
        </nav>
        <div data-v-7cd1ca14=""  data-wow-delay="0.5s" class="row advanced-pricing-table no-gutters wow pixFadeUp"
             style="visibility: visible; animation-delay: 0.5s; animation-name: i;display: flex;justify-content: center;">
          <div data-v-7cd1ca14="" class="col-lg-3">
            <div data-v-7cd1ca14="" class="pricing-table br-left">
              <div data-v-7cd1ca14="" class="pricing-header pricing-amount " :class="{'change-subs-duration': (anual===1)}">
                <div data-v-7cd1ca14="" class="annual_price" style="margin-bottom: 195px;"><h2 data-v-7cd1ca14=""
                                                                                               class="price">Grátis</h2>
                </div>
                <div data-v-7cd1ca14="" class="monthly_price"><h2 data-v-7cd1ca14="" class="price">Grátis</h2></div>
                <h3 data-v-7cd1ca14="" class="price-title">MEI</h3>
                <p data-v-7cd1ca14="">Pode ser usado por até um ano.</p></div>
              <ul data-v-7cd1ca14="" class="price-feture">
                <li  data-v-7cd1ca14="">Taxa de Setup: R$ 39,90</li>
                <li data-v-7cd1ca14="">Limite de Comandas Mensal: 300</li>
                <li data-v-7cd1ca14="">Terminal Adicional: Não</li>
                <li data-v-7cd1ca14="">Número de Terminais: 1</li>
                <li data-v-7cd1ca14="">Operadores: 3</li>
                <li data-v-7cd1ca14="">Ambientes: 2</li>
              </ul>
              <div data-v-7cd1ca14="" class="action text-center"><a  :href="'https://assine.jassy.app.br/?plan=1&period='+((anual===1)?'anual':'mensal')"
                                                                    class="pix-btn btn-outline">Contratar</a></div>
            </div>
          </div>          
          <div data-v-7cd1ca14="" class="col-lg-3">
            <div data-v-7cd1ca14="" class="pricing-table color-three">
              <div data-v-7cd1ca14="" class="pricing-header pricing-amount"  :class="{'change-subs-duration': (anual===1)}">
                <div data-v-7cd1ca14="" class="annual_price"><h2 data-v-7cd1ca14="" class="price"
                                                                 style="text-decoration: line-through;">R$ 3.118,80</h2>
                  <h2 data-v-7cd1ca14="" class="price" style="color: limegreen; font-size: 16px; margin-bottom: 0px;">
                    20% de desconto</h2>
                  <h2 data-v-7cd1ca14="" class="price" style="margin-bottom: 0px;">R$ 2.495,04</h2>
                  <h2 data-v-7cd1ca14="" class="price" style="color: limegreen; font-size: 16px;">Até 12x sem juros</h2>
                </div>
                <div data-v-7cd1ca14="" class="monthly_price"><h2 data-v-7cd1ca14="" class="price">R$ 149,90</h2></div>
                <h3 data-v-7cd1ca14="" class="price-title">Basic</h3>
                <p data-v-7cd1ca14="">Pra você que já tem um grande fluxo</p></div>
              <ul data-v-7cd1ca14="" class="price-feture">
                <li v-if="anual!==1" data-v-7cd1ca14="">Taxa de Setup: R$ 199,90</li>
                <li v-else data-v-7cd1ca14="">Taxa de Setup: Grátis</li>
                <li data-v-7cd1ca14="">Sem limite de comandas mensal</li>
                <li data-v-7cd1ca14="">Terminal Adicional: R$ 29,90</li>
                <li data-v-7cd1ca14="">Número de Terminais: 4</li>
                <li data-v-7cd1ca14="">Operadores Ilimitados</li>
                <li data-v-7cd1ca14="">Ambientes Ilimitados</li>
              </ul>
              <div data-v-7cd1ca14="" class="action text-center"><a :href="'https://assine.jassy.app.br/?plan=2&period='+((anual===1)?'anual':'mensal')"
                                                                    class="pix-btn btn-outline">Contratar</a></div>
            </div>
          </div>          
        </div>
      </div>
    </section>
    <section class="call-to-action"><a name="contato"></a>
      <div class="container">
        <div class="action-content text-center wow pixFadeUp" style="visibility: visible; animation-name: i;"><h2
            class="title">Ficou interessado?</h2>
          <p>Entre em contato com a gente, será um prazer te atender.</p>
          <div><input type="text" v-model="contact.nome" placeholder="Seu nome">
            <input type="text" :disabled="(send===1)" v-model="contact.email" placeholder="Seu e-mail">
            <input type="text" :disabled="(send===1)" v-model="contact.telefone" placeholder="Seu telefone">
            <input type="text" :disabled="(send===1)" v-model="contact.estabelecimento" placeholder="Seu estabelecimento">
            <input type="text" :disabled="(send===1)" v-model="contact.cidade" placeholder="Sua cidade">
            <input type="text" :disabled="(send===1)" v-model="contact.mensagem" placeholder="Mensagem">
            <a data-recording-click-props='{"contato":true}' class="pix-btn btn-light" @click="doSend()">Enviar</a>
          </div>
        </div>
      </div>
    </section>

    <div id="whatsapp"><a href="https://wa.me/555140427702?text=Quero saber mais sobre o JasSy">Converse com a gente</a></div>
  </div>
</template>

<script>

import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

const instance = axios.create({
  baseURL: 'https://api.jassy.app.br/api',
  timeout: 30000,
  validateStatus: function (status) {
    return status === 200; // Resolve only if the status code is less than 500
  }
});


Vue.use(VueAxios, instance)

export default {
  instance,
  name: 'App',
  data: function(){
    return {
      tab: 1,
      anual: 0,
      send: 0,
      contact: {
        nome: '',
        telefone: '',
        estabelecimento: '',
        cidade: '',
        email: '',
        mensagem: ''
      }
    }
  },
  methods: {
    go: function(period,plan){

      // eslint-disable-next-line no-unused-vars
      var value = 0;
      switch(plan){
          case 1:
            value = 0;
            break;
          case 2:
            value = (period==='mensal')?185.85:990.10;
            break
          case 3:
            value = (period==='mensal')?355.96:1945.26;
            break;
          case 4:
            value = (period==='mensal')?0:4900.00;
      }

      // eslint-disable-next-line no-undef
      //fbq('track', 'AddToCart',{currency: 'BRL',value: value});

      //document.location = 'https://assine.jassy.app.br/?plan='+plan+'&period='+period;
    },
    doSend: function(){
      if(this.send===1){ return false; }
      this.send = 1;

        this.$http.post('/v1/leads',this.contact).then(()=>{
          this.send = 0;
            alert('Sua mensagem foi enviada com sucesso.');
        }).catch(()=>{
          alert('Desculpe, ocorreu um erro inesperado, envie sua mensagem diretamente para contato@kore.ag');
          this.send = 0;
        });
    }
  }
}
</script>

<style>

#whatsapp{
  position:fixed;
  z-index:9999;
  bottom:0px;
  right:30px;
  background: #007bff;
  padding:10px;
  color:white;
  border-radius:10px 10px 0px 0px;
}

#whatsapp a{
  display:block;
  color:white;
  line-height:40px;
  margin: 5px;
  background: #007bff url('/img/fbMessenger.png') no-repeat;
  background-position: center right;
  background-size: auto 90%;
  padding-right:40px;
}

</style>
